.App {
    font-family: 'Noto Sans Mono', monospace;
    font-size: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
}

.container {
    padding: 1rem;
    border: 1px solid #333;
    background: azure;
}

.card-container {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.card {
    width: 100%;
    max-width: 100%;
    margin-top: 2rem;
    padding: 1rem;
}

.card:first-of-type {
    margin-right: 5%;
    margin-bottom: 2rem;
}

@media screen and (min-width: 767px) {
    .card-container {
        flex-direction: row;
    }
    .card:first-of-type {
        border-right: 1px solid #333;
        margin-bottom: 0
    }
    .card {
        width: 300px;
    }
}